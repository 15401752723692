@use "../shared/clearfix"

// Social links
.social-links
  // Contains pixel sized images - keep this sized in pixels
  margin-bottom: 2em
  margin-top: 2em

.social-links-list
  +clearfix.pie-clearfix
  margin-left: auto
  margin-right: auto
  list-style-type: none
  padding-left: 0
  width: 205px

.social-links-list-item
  background-color: #cccccc
  background-position: 0 0
  background-repeat: no-repeat
  float: left
  margin-right: 1px
  a
    background-position: 0 -50px
    border: none
    display: block
    height: 50px
    opacity: 0
    padding: 0
    text-indent: -9999em
    transition: all ease 1s
    width: 50px
    &:hover
      background-color: #cccccc
      opacity: 1
      transition: all ease-in 0.15s

.social-links
  .github, .github a
    background-image: url(../images/social/github.png)
  .linked_in, .linked_in a
    background-image: url(../images/social/linked_in.png)
  .stack_overflow, .stack_overflow a
    background-image: url(../images/social/stack_overflow.png)
  .twitter, .twitter a
    background-image: url(../images/social/twitter.png)
  .working_with_rails, .working_with_rails a
    background-image: url(../images/social/working_with_rails.png)
